:root {
  --backgroundColor: #c58500;
}

footer {
  width: 100%;
}

.date {
  width: 100%;
  font-size: 20px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  height: 3%;
}

// Stoy button
a.studioStoyLink {
  position: relative;
  top: -4rem;
  text-decoration: none;
  font-weight: bold;

  background-color: var(--backgroundColor);

  color: #ffffff;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 8px;
  transition: box-shadow 0.3s, background-color 0.2s ease-in-out;
}

a.studioStoyLink:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: scale(10);
  color: #ffcc00;
}

a.studioStoyLink:hover::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  z-index: -1;
  animation: shiningEffect 1s infinite;
}

a.studioStoyLink:active {
  background-color: darkgray;
}

.g-footer {
  position: absolute;
  bottom: -10px;
  right: -4px;
  height: 10rem;
  width: 110%;
  background: var(--backgroundColor);
  filter: blur(5px);

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    backdrop-filter: blur(5px);
  }
}

.g-bubble {
  position: absolute;
  border-radius: 50%;
  background-color: var(--backgroundColor);
}

@for $i from 0 through 50 {
  .g-bubble:nth-child(#{$i}) {
    $width: 50 + random(100) + px;
    left: #{(random(100)) + '%'};
    top: #{(10 + random(100))}px;
    width: $width;
    height: $width;
    animation: moveToTop #{calc((random(500) + 500) / 100)}s ease-in-out -#{calc(random(2000)/1000)}s infinite;
  }
}

@keyframes moveToTop {
  90% {
    opacity: 1;
  }
  100% {
    opacity: .08;
    transform: translate(-50%, -120px) scale(.3);
  }
}

@keyframes shiningEffect {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.76);
  }
  50% {
    box-shadow: 0 0 10px 8px rgba(255, 255, 255, 0.27);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  }
}