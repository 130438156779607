.coin-button {
    background-color: #575757;
    color: #ffffff;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    transition: transform 0.3s, box-shadow 0.3s, background-color 0.2s ease-in-out;
}

.coin-button:hover {
    background-color: rgb(225, 173, 1);

    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.coin-button:hover::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
    z-index: -1;
    animation: shiningEffect 1s infinite;
}

.coin-button:active {
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgb(255, 204, 0);
}

@keyframes shiningEffect {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.76);
    }
    50% {
        box-shadow: 0 0 10px 8px rgba(255, 255, 255, 0.12);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.15);
    }
}