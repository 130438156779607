.logo {
    cursor: pointer;
    transform: scale(1);
    height: 10em;
    transition: transform 0.3s ease-in-out;
}

.logo:hover {
    transform: scale(1.1);
}

.logo:active {
    transform: scale(0.9);
}

.App-logo {
    pointer-events: none;
    height: 10em;
}

h1 {
    font-size: xx-large;
    margin: 0;
}

.text {
    margin: 0;
}

body {
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #222;
}

.info-menu {
    opacity: 0;
    /*transform: scale(0.5);*/
    pointer-events: none; /* Prevent interactions with hidden modal */
    transition: all 0.3s ease;
}

.info-menu.active {
    opacity: 1;
    pointer-events: auto;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: coin-bounce-spin infinite 16s ease-in-out;
    }
}

@keyframes coin-bounce-spin {
    0% {
        transform: rotate(-30deg);
    }
    25% {
        transform: rotate(30deg);
    }
    50% {
        transform: rotate(-30deg);
    }
    75% {
        transform: rotate(30deg);
    }
    100% {
        transform: rotate(-30deg);
    }
}