input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(100%) sepia(0%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
}

.timePickers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    width: 100%;
}

.hourDescription {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.hourInput {
    background-color: #575757;
    color: #ffffff;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 8px;
    margin-left: 1rem;
    width: 43%;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    pointer-events: none; /* Prevent interactions with hidden modal */
    transition: all 0.3s ease;
}

.modal.active {
    opacity: 1;
    pointer-events: auto;
}

.modal-content {
    background-color: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 3rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    transform: scale(0.5);
    transition: all 0.3s ease;
}

.modal-content.active {
    transform: scale(1);
    pointer-events: auto;
}

.close {
    position: relative;
    top: -10px;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
    color: #888;
}

.close:hover {
    color: var(--backgroundColor);
}

@media (max-width: 720px) {
    .timePickers {
        grid-template-columns: auto;
    }

    .modal-content {
        width: 90%;
    }
}
